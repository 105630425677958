<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Dettaglio presenza</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <div>
          <div>
            <ion-label>
              Risorsa:
            </ion-label>
            <ion-item>
              <ion-input readonly>
                {{holiday.user_name}} ({{holiday.user}})
              </ion-input>
            </ion-item>
          </div>
          <div>
            <ion-label>
              Tipologia:
            </ion-label>
            <ion-item>
              <ion-select id="selezione" :disabled="holiday.stato!=3 ? true : false" v-model="holiday.type" style="font-weight:bold">
                <ion-select-option id="selezione" value="ferie">Ferie</ion-select-option>
                <ion-select-option  id="selezione" value="permesso">Permesso</ion-select-option>
                <ion-select-option id="selezione" value="malattia">Malattia</ion-select-option>
                <ion-select-option  id="selezione" value="lutto">Lutto</ion-select-option>
                <ion-select-option id="selezione" value="infortunio">Infortunio</ion-select-option>
                <ion-select-option  id="selezione" value="congedo parentale">Congedo parentale</ion-select-option>
                <ion-select-option id="selezione" value="cassa integrazione">Cassa integrazione</ion-select-option>
                <ion-select-option id="selezione" value="smart working">Smart Working</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
          <div>
            <ion-label>
              Formato:
            </ion-label>
            <ion-item>
              <ion-select id="format" :disabled="holiday.stato!=3 ? true : false" v-model="holiday.format" style="font-weight:bold">
                <ion-select-option id="formato" value="giornate">Giornate</ion-select-option>
                <ion-select-option  id="formato" value="ore">Ore</ion-select-option>             
              </ion-select>
            </ion-item>
          </div>
          <div>
            <ion-label v-if="holiday.format=='giornate'">
              Data di inizio:
            </ion-label>
            <ion-label v-if="holiday.format=='ore'">
              Data:
            </ion-label>
            <ion-item>
              <ion-datetime :min="minDate" :max="maxDate" display-format="DD-MM-YYYY" :readonly="holiday.stato!=3 ? true : false" v-model="holiday.begindate"></ion-datetime>
            </ion-item>
          </div>
          <div v-if="holiday.format=='giornate'">
            <ion-label>
              Data di fine:
            </ion-label>
            <ion-item>
              <ion-datetime :min="minDate" :max="maxDate" display-format="DD-MM-YYYY" :readonly="holiday.stato!=3 ? true : false" v-model="holiday.enddate"></ion-datetime>
            </ion-item>
          </div>
          <div v-if="holiday.format=='ore'">
            <ion-label>
              Ora di inizio:
            </ion-label>
            <ion-item>
              <ion-datetime display-format="HH:mm" :readonly="holiday.stato!=3 ? true : false" v-model="holiday.begintime"></ion-datetime>
            </ion-item>
          </div>
          <div v-if="holiday.format=='ore'">
            <ion-label>
              Ora di fine:
            </ion-label>
            <ion-item>
              <ion-datetime display-format="HH:mm" :readonly="holiday.stato!=3 ? true : false" v-model="holiday.endtime"></ion-datetime>
            </ion-item>
          </div>
          <div>
           <ion-label>
              Note:
            </ion-label>
            <ion-item>
              <ion-input :readonly="holiday.stato!=3 ? true : false" v-model="holiday.note"></ion-input>
            </ion-item>
          </div>
        </div>
      </div>
      <div v-if="user.role<5 && holiday.stato==3">
         <ion-button id="approva" color="primary" @click="modifica()">MODIFICA RICHIESTA</ion-button>
         <ion-button id="elimina" color="warning" @click="deleteHoliday()">ANNULLA RICHIESTA</ion-button>
      </div>
      <div v-if="user.role>4 && holiday.stato==3">
          <ion-button id="approva" color="success" @click="approva()">APPROVA</ion-button>
          <ion-button id="respingi" color="danger" @click="respingi()">RESPINGI</ion-button>
      </div>
      <div v-if="user.role>4 && holiday.stato!=3">
         <ion-button id="elimina" color="warning" @click="deleteHoliday()">ANNULLA RICHIESTA</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
  import { 
    IonBackButton,
    IonButton,
    IonButtons, 
    IonContent, 
    IonDatetime,
    IonHeader,
    IonItem, 
    IonInput,
    IonLabel,
    IonMenuButton, 
    IonPage,
    IonSelect,
    IonSelectOption, 
    IonTitle, 
    IonToolbar,
    toastController
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonBackButton,
      IonButton,
      IonButtons, 
      IonContent, 
      IonDatetime,
      IonHeader,
      IonItem, 
      IonInput,
      IonLabel,
      IonMenuButton, 
      IonPage,
      IonSelect,
      IonSelectOption, 
      IonTitle, 
      IonToolbar
    },
    data: function(){
      return{
        user:{
          name:'',
          email:'',
          password:'',
          role:''
        },
        holiday:{
          user:'',
          type:'',
          begindate:'',
          enddate:'',
          begintime:'',
          endtime:'',
          note:'',
          stato:0,
          formato:'',
        },
        minDate:'',
        maxDate:'',
      }
    },
    methods:{
      getHolidayInfo(){
        this.axios.post(this.apiUrl + 'get-holiday-info', {id:this.$route.params.id}, this.headers)
        .then(async (response) => {
          this.holiday=response.data.holiday;
        })
      },
      getConsultant(){
        this.axios.post(this.apiUrl + 'get-userInfo', {id:this.holiday.user}, this.headers)
        .then(async (response) => {
          this.user=response.data.user;
          this.minDate=response.data.now;
          this.maxDate=response.data.fiveYear;
        })
      },
      approva(){
        this.holiday.stato=1;
        /*if(this.holiday.format=='giornate'){
          this.holiday.begintime='';
          this.holiday.endtime='';
        }else if(this.holiday.format=='ore'){
          this.holiday.enddate='';
        }*/
        this.axios.post(this.apiUrl + 'aggiorna-stato-holiday', {id:this.$route.params.id, holiday:this.holiday}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            const toast = await toastController
            .create({
              color: 'success',
              message: response.data.message,
              duration: 2000
            })
            this.$router.push('/folder/report-permessi');
            return toast.present();
          }
          window.location.reload();
        })
      },
      respingi(){
        this.holiday.stato=2;
        this.axios.post(this.apiUrl + 'aggiorna-stato-holiday', {id:this.$route.params.id, holiday:this.holiday}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            const toast = await toastController
            .create({
              color: 'success',
              message: response.data.message,
              duration: 2000
            })
            this.$router.push('/folder/report-permessi');
            return toast.present();
          }
          window.location.reload();
        })
      },
      modifica(){
        
        if(this.holiday.format=='giornate'){
          this.holiday.begintime='';
          this.holiday.endtime='';
        }else if(this.holiday.format=='ore'){
          this.holiday.enddate='';
        }
        this.axios.post(this.apiUrl + 'aggiorna-utente-holiday', {id:this.$route.params.id, holiday:this.holiday}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            const toast = await toastController
            .create({
              color: 'success',
              message: response.data.message,
              duration: 2000
            })
            this.$router.push('/folder/report-permessi');
            return toast.present();
          }
        })
      },
      deleteHoliday(){
        this.axios.post(this.apiUrl + 'delete-holiday', {id:this.$route.params.id}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.$router.push('/folder/report-permessi');   
          }         
        });
      },
    },
    ionViewWillEnter: function(){
      this.getHolidayInfo();
      this.getConsultant();
    }
  }
</script>
<style scoped>
#container{
  width:100%;
}

</style>